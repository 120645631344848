$(document).foundation();

$(document).ready(function(){
    $('.slick-row-slider').slick({
      dots: true,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      centerMode: false,
      variableWidth: true,
      arrows: false,
    });

    $('.slick-row-slider a').imageLightbox({
        activity: true,
        overlay: true,
        button: true,
        quitOnDocClick: false,
        caption: true,
        arrows: true
    });

    $('.round-slider').slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        fade: true,
    });
});

$(document).on('open.zf.reveal', "#imprint", function(e) {
	var $modal = $(this);
	var ajax_url = $modal.data("ajax-url");
	if (ajax_url) {
		$modal.html("Lade...");
		$.ajax(ajax_url).done(function(response) {
			$modal.html(response);
		});
	}
});
$(document).on('open.zf.reveal', "#privacy", function(e) {
	var $modal = $(this);
	var ajax_url = $modal.data("ajax-url");
	if (ajax_url) {
		$modal.html("Lade...");
		$.ajax(ajax_url).done(function(response) {
			$modal.html(response);
		});
	}
});